<template>
  <div>

    <div class="section">

      <div class="container">
        <h1 class="ml-5 text-center"> Загрузка файлов </h1>


        <div class="filter-panel">
          Выберите период времени:
          <flat-pickr width=300 id="dateSelector" class="datebox-container mr-2" v-model="input_api.currentDatesText"
            :config="flatPickrOptions">
          </flat-pickr>
          <div class="row">
            <div class="col-4">
              <div class="form-group">
                <label>Время от: <span class="text-danger">*</span></label>
                <div class="position-relative">

                  <input class="form-control pl-5" placeholder="00:00" name="hour_from" required=""
                    v-model="input_api.hour_from" />
                </div>

                <label>Время до: <span class="text-danger">*</span></label>
                <div class="position-relative">

                  <input class="form-control pl-5" placeholder="12:00" name="hour_to" required=""
                    v-model="input_api.hour_to" />
                </div>
              </div>
            </div>
          </div>
          <a id="div-with-loading" class="btn btn-info vs-con-loading__container ml-3 mb-6"
            @click="startProcessing">Начать загрузку</a>
        </div>
      </div>
      <p v-if="rows_total > 0" class="mb-6">Статус: {{ rows_run }} из {{ rows_total }}</p>

      <div class="row m-12 justify-content-center align-items-center">


        <div class="col-11">
          <div class="table-responsive bg-white shadow rounded">
            <table class="table mb-0 table-center" style="width:100%;">
              <thead>
                <tr>

                  <th scope="col">Время От</th>
                  <th scope="col"> Время До</th>
                  <th scope="col"> Файл</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(x, ix) in filesList">

                  <td>{{ x.date_from }}</td>
                  <td>{{ x.date_to }}</td>
                  <td> <a @click="urlS3(x.file_name)" @mouseover="hover = true" @mouseleave="hover = false"
                      :class="{ active: hover }">{{ x.file_name }}</a>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>





      </div>




    </div>
  </div>
</template>

<script>


import store from "@/store/store";


let sseClient;

import { DownloadIcon, ArrowRightCircleIcon } from "vue-feather-icons";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";


export default {
  name: "sml_radio_sse",
  components: {
    flatPickr,
    DownloadIcon, ArrowRightCircleIcon,
  },
  data() {
    return {

      hover: false,

      filesList: [],

      rows_run: 0,
      rows_total: 0,

      input_api: {
        user_id: 1,
        currentDatesText: '',
        hour_from: '00:00',
        hour_to: '12:00',

      },
      flatPickrOptions: {
        mode: "range",
        maxDate: new Date().setDate(new Date().getDate() + 1),
        dateFormat: "Y-m-d",
        defaultDate: [new Date().setDate(new Date().getDate()), new Date().setDate(new Date().getDate())],
        onChange: this.onChangeHandler,
      },


    };
  },

  created() {
    this.getFileList();
  },
  mounted() {


    sseClient = this.$sse.create({
      url: '' + this.$hostnamejava + '/ba2excel/sse-subscribe',
      format: 'json',
      withCredentials: false,
      polyfill: true,
    });
    /*
    // Catch any errors (ie. lost connections, etc.)
    sseClient.on('error', (e) => {
      console.error('lost connection or failed to parse!', e);

      // If this error is due to an unexpected disconnection, EventSource will
      // automatically attempt to reconnect indefinitely. You will _not_ need to
      // re-add your handlers.
    });
    */
    // Handle messages without a specific event
    sseClient.on('bamacro', this.handleMessage);
    /*
    // Handle 'chat' messages
    sseClient.on('chat', this.handleChat);

    // Handle once for a ban message
    sseClient.once('ban', this.handleBan);
    */
    sseClient.connect()
      .then(sse => {
        console.log('We\'re connected!');
        /*
        // Unsubscribes from event-less messages after 7 seconds
        setTimeout(() => {
          sseClient.off('message', this.handleMessage);
          console.log('Stopped listening to event-less messages!');
        }, 35000);

        // Unsubscribes from chat messages after 14 seconds
        setTimeout(() => {
          sse.off('chat', this.handleChat);
          console.log('Stopped listening to chat messages!');
        }, 35000);*/
      })
      .catch((err) => {
        // When this error is caught, it means the initial connection to the
        // events server failed.  No automatic attempts to reconnect will be made.
        console.error('Failed to connect to server', err);
      });
  },
  methods: {


    urlS3(filename) {
      let url = '' + this.$hostnamejava + '/ba2excel/bamacro-file/' + filename
      window.open(url, '_blanc')
    },



    handleBan(banMessage) {
      // Note that we can access properties of message, since our parser is set to JSON
      // and the hypothetical object has a `reason` property.
      this.messages.push(`You've been banned! Reason: ${banMessage.reason}`);
    },
    handleChat(message) {
      // Note that we can access properties of message, since our parser is set to JSON
      // and the hypothetical object has these properties.
      this.messages.push(`${message.user} said: ${message.text}`);
    },
    handleMessage(message, lastEventId) {
      // console.warn('Received a message w/o an event!', message, lastEventId);

      this.rows_run = message.rows_run;
      this.rows_total = message.rows_total;
    },

    getFileList() {
      this.input_api.user_id = store.state.user_id;
      this.axios({
        method: 'post',
        url: '' + this.$hostnamejava + '/ba2excel/get-list-file',
        data: this.input_api,
      }).then((res) => {
        this.filesList = res.data;
      });

    },

    startProcessing() {
      this.input_api.user_id = store.state.user_id;

      this.$vs.loading({
        container: '#div-with-loading',
        //container: this.$refs.loginButton.$el,
        scale: '0.45',
      });


      this.axios({
        method: 'post',
        url: '' + this.$hostnamejava + '/ba2excel/get-data',
        data: this.input_api,
      }).then((res) => {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        this.$vs.notify({
          time: 12000,
          title: 'Успех! ',
          text: 'Файл готов. Обновите страницу.',
          color: "success"
        })
        /* for (let i = 0; i < res.data.subject.length; i++) {
           this.seriesLine.push[{data:res.data.subject[i].probabilities}];
         }
           */
      });
    },
  },
  beforeDestroy() {
    // Make sure to close the connection with the events server
    // when the component is destroyed, or we'll have ghost connections!
    sseClient.disconnect();

    // Alternatively, we could have added the `sse: { cleanup: true }` option to our component,
    // and the SSEManager would have automatically disconnected during beforeDestroy.
  },



}
</script>


<style lang="scss">
.active {
  cursor: pointer;
}
</style>
